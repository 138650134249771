<template>
  <div class="content-party">
    <div v-for="item in datas" :key="item.id">
      <div class="content-title">
        {{ item.title }}
      </div>

      <div class="content-group">
        <template v-for="(field, indexItem) in item.dynamicFieldList">
          <k-carousel
            v-if="field.photos"
            :interval="interval"
            :controls="true"
            indicators
            :background="background"
            style="margin-bottom: 28px; text-shadow: 1px 1px 2px #333"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
            :key="`carousel-${indexItem}`"
          >
            <k-carousel-slide
              v-for="(photo, index) in field.photos"
              :key="index"
            >
              <template #img>
                <img
                  class="img-fluid d-block"
                  :src="photo.base64String"
                  alt="image slot"
                />
              </template>
            </k-carousel-slide>
            <div class="noPhotoImage" v-if="field.photos.length == 0">
              <div class="noPhotoFont" style="font-size: 2rem">
                {{ $g("images.noPhotoText") }}
              </div>
            </div>
          </k-carousel>
          <k-form-group
            v-if="!field.photos"
            :label-suffix="field.title"
            label-align-md="right"
            label-cols-md="6"
            content-cols-lg="6"
            content-cols-md="7"
            :noLocale="true"
            :key="`value-${indexItem}`"
          >
            <k-form-input v-model="field.value" :plaintext="true" />
          </k-form-group>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { getAccessibilityPhotos } from "@/api/workAssignments";

export default {
  components: {},
  props: {
    eleFacilityId: {
      type: String,
      default() {
        return "";
      },
    },
    interval: {
      type: Number,
      default() {
        return 0;
      },
    },
    background: {
      type: String,
      default: "",
    },
    defaultId: {
      type: String,
      default() {
        return "";
      },
    },
    title: {
      type: String,
      default() {
        return this.$g("images.photos");
      },
    },
  },

  data() {
    return {
      datas: [],
    };
  },

  methods: {
    async bindPhotoes() {
      await getAccessibilityPhotos(this.eleFacilityId).then((res) => {
        this.datas = res.data || [];
      });
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    refresh() {
      this.bindPhotoes();
    },
  },

  mounted() {
    this.bindPhotoes();
  },
  watch: {
    eleFacilityId() {
      this.bindPhotoes();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .carousel.slide {
    width: 80%;
    margin: 0 auto;
  }

  .carousel-control-prev {
    left: -50px !important;
    width: auto !important;
  }

  .carousel-control-next {
    right: -50px !important;
    width: auto !important;
  }

  .carousel-control-prev .carousel-control-prev-icon,
  .carousel-control-next .carousel-control-next-icon {
    background-color: #aaa;
    padding: 5px;
    box-sizing: content-box;
    border-radius: 50%;
    background-size: 70%;
  }

  .carousel-item {
    height: 400px;
  }

  .carousel-item .img-fluid {
    width: auto !important;
    max-height: 100%;
    margin: 0 auto;
    cursor: pointer;
  }

  .rightBtn {
    float: right;
  }

  .field-group {
    padding: 10px 0;
    margin-bottom: 0.75rem;
  }
}
</style>
